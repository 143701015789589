import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { TextInput, Card, Select, SelectItem } from "@tremor/react";
import { Typography, Button, Navbar } from '@material-tailwind/react';
import { FaSave, FaBan } from 'react-icons/fa';
import * as yup from 'yup';
import _ from 'lodash';

import AlertManager from '../../general/AlertManager.jsx';
import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard.jsx';
import EditableTextInput from '../general/EditableTextInput.jsx';

import useProjectTestType from '../../../datahooks/admin/project/useProjectTestType.js';
import useProjectTestTypeManager from '../../../datahooks/admin/project/useProjectTestTypesManager.js';
import useTestTypes from '../../../datahooks/admin/project/useTestTypes.js';
import useTestTypeProviders from '../../../datahooks/admin/project/useTestTypeProviders.js';
import { providerConfigs } from '../../../datahooks/admin/project/providerConfigs.js';

const testTypeSchema = yup.object().shape({
  test_type_id: yup.number().integer()
    .required('Test type is required')
    .min(0, 'Please select a test type'),
  provider_type: yup.number().integer()
    .required('Provider is required')
    .min(0, 'Please select a provider'),
  api_url: yup
    .string()
    .url('Please enter a valid URL.')
    .required('API URL is required.')
    .max(255, 'API URL should not exceed 255 characters.'),
  api_key: yup
    .string()
    .optional()
    .max(255, 'API key should not exceed 255 characters.'),
  api_username: yup
    .string()
    .optional()
    .max(255, 'API username should not exceed 255 characters.'),
  api_password: yup
    .string()
    .optional()
    .max(255, 'API password should not exceed 255 characters.'),
  assets_url: yup
    .string()
    .url('Please enter a valid URL.')
    .optional()
    .max(255, 'Assets URL should not exceed 255 characters.'),
});

const ProjectTestTypesEditForm = ({ acdId = -1, addingTestType, onCancel, onUpdatedTestType, projectId }) => {
  // States
  const [initialValues, setInitialValues] = useState({
    test_type_id: -1,
    provider_type: -1,
    api_url: '',
    api_key: '',
    api_username: '',
    api_password: '',
    assets_url: ''  // Add this
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentConfig, setCurrentConfig] = useState(providerConfigs["-1"]);
  const [availableProviders, setAvailableProviders] = useState([]);

  // Hook callbacks
  const projectTestTypeUpdated = (aResponse) => {
    if (!addingTestType) {
      setSuccessMessage("Project test type updated successfully."); 
      refetchProjectTestTypes();
    }
    
    if (onUpdatedTestType) {
      onUpdatedTestType();
    }
  };

  const projectTestTypeSaveFailed = (error) => {
    setErrorMessage(`Failed to save project test type: ${error?.errorResponse?.message || 'Unknown error'}`);
  };

  // Hooks
  const { performAction } = useProjectTestTypeManager(projectTestTypeUpdated, projectTestTypeSaveFailed);
  const { testTypesData, loadingTestTypes, testTypesError } = useTestTypes(addingTestType);
  const { projectTestTypeData, loadingProjectTestType, refetchProjectTestTypes, projectTestTypeError } = useProjectTestType(acdId, addingTestType);
  const { providersData, getProvidersForTestType, loadingProviders, providersError } = useTestTypeProviders();
  
  // Check if any required data is still loading
  const isLoading = loadingProjectTestType || loadingTestTypes || loadingProviders;

  // This effect will run when the test type, project test type or providers data changes
  useEffect(() => {
    const getDefaultInitialValues = () => ({
      test_type_id: -1,
      provider_type: -1,
      api_url: '',
      api_key: '',
      api_username: '',
      api_password: '',
      assets_url: ''
    });

    if (addingTestType) { // Reset the form when adding a new test type
      setCurrentConfig(providerConfigs["-1"]); // Default state when adding
      const newInitialValues = getDefaultInitialValues();

      // If the initial values have changed, update the form
      if (!_.isEqual(initialValues, newInitialValues)) {
        setInitialValues(newInitialValues);
      }

    } else if (projectTestTypeData && providersData) { // Update the form when editing an existing test type
      const newInitialValues = { // Load the existing values
        test_type_id: projectTestTypeData.testTypeId || -1,
        provider_type: projectTestTypeData.provider_type || -1,
        api_url: projectTestTypeData.api_url || '',
        api_key: projectTestTypeData.api_key || '',
        api_username: projectTestTypeData.api_username || '',
        api_password: projectTestTypeData.api_password || '',
        assets_url: projectTestTypeData.assets_url || '',
      };
      
      // If the initial values have changed, update the form
      if (!_.isEqual(initialValues, newInitialValues)) {
        // Get the providers for the selected test type
        const providers = getProvidersForTestType(projectTestTypeData.testTypeId);
        setAvailableProviders(providers);
        setCurrentConfig(providerConfigs[projectTestTypeData.provider_type] || providerConfigs["-1"]);
        setInitialValues(newInitialValues);
      }
    }
  }, [isLoading, addingTestType, projectTestTypeData, providersData]);

  const handleSubmit = async (values) => {
    try {
      await testTypeSchema.validate(values, { abortEarly: false });
      await doSave();
    } catch (error) {
      const validationErrors = error.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      formik.setErrors(validationErrors);
    }
  };

  const doSave = async () => {
    if (addingTestType) {
      if (!formik.values.test_type_id || !formik.values.provider_type || !formik.values.api_url) {
        console.error("Test type, provider and URL are required.");
        return;
      }
      await doAddTestType();
    } else {
      await doUpdateTestType();
    }
  };

  const doAddTestType = async () => {
    const payload = {
      new_project_id: projectId,
      new_test_type_id: formik.values.test_type_id,
      new_provider_type: formik.values.provider_type,
      new_api_url: formik.values.api_url
    };
  
    if (formik.values.api_key) { payload.new_api_key = formik.values.api_key; }
    if (formik.values.api_username) { payload.new_api_username = formik.values.api_username; }
    if (formik.values.api_password) { payload.new_api_password = formik.values.api_password; }
    if (formik.values.assets_url) { payload.new_assets_url = formik.values.assets_url; }  // Add this
  
    return performAction(payload, 'PUT'); 
  };

  const doUpdateTestType = () => {
    const payload = { api_id: acdId };
    
    if (!_.isEqual(initialValues.api_url, formik.values.api_url)) { 
      payload.new_api_url = formik.values.api_url; 
    }

    if (!_.isEqual(initialValues.provider_type, formik.values.provider_type)) { 
      payload.new_provider_type = formik.values.provider_type; 
    }

    if (!_.isEqual(initialValues.api_username, formik.values.api_username)) { 
      payload.new_api_username = formik.values.api_username; 
    }

    if (!_.isEqual(initialValues.api_password, formik.values.api_password)) { 
      payload.new_api_password = formik.values.api_password; 
    }

    if (!_.isEqual(initialValues.api_key, formik.values.api_key)) { 
      payload.new_api_key = formik.values.api_key; 
    }

    if (!_.isEqual(initialValues.assets_url, formik.values.assets_url)) { 
      payload.new_assets_url = formik.values.assets_url; 
    }

    if (Object.keys(payload).length > 1) {
      return performAction(payload, 'POST');
    }
  };

  const handleTestTypeChange = (value) => {
    formik.setFieldValue('test_type_id', value);
    formik.setFieldValue('provider_type', -1)

    const providers = getProvidersForTestType(value);
    setAvailableProviders(providers);
    setCurrentConfig(providerConfigs["-1"]);
  };

  const handleProviderChange = (value) => {
    formik.setFieldValue('provider_type', value);
    setCurrentConfig(providerConfigs[value] || providerConfigs["-1"]);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: testTypeSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full min-h-[400px]">
        <LoadingIcon showText />
      </div>
    );
  }

  if (projectTestTypeError || testTypesError || providersError) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full min-h-[400px]">
        <ErrorCard cardType={CARD_TYPES.EMPTY} /> 
        {projectTestTypeError?.message || testTypesError?.message || providersError?.message}
      </div>
    );
  }

  return (
    <Card data-testid="project-test-type-form" className="flex flex-col w-full h-full text-blue-500 items-center">
      {addingTestType ? (
        <Typography data-testid="pttf-title" variant="h5" className="text-blue-500 mt-2">Add New Test Type</Typography>
      ) : (
        <Typography data-testid="pttf-title" variant="h5" className="text-blue-500 mt-4">Test Type: {projectTestTypeData?.testTypeTitle}</Typography>
      )}

      <form onSubmit={formik.handleSubmit} className="flex flex-col w-full max-w-4xl">
        {addingTestType && (
          <>
            <Typography data-testid="pttf-test-type-title" variant="h5" className="text-blue-500 mt-4">Test Type</Typography>
            <Select
              data-testid="pttf-test-type-select"
              className="mt-Select-button"
              label="Test Type"
              value={formik.values.test_type_id}
              onChange={handleTestTypeChange}
              onBlur={() => formik.setFieldTouched('test_type_id', true)}
              error={formik.touched.test_type_id && Boolean(formik.errors.test_type_id)}
            >
              {testTypesData?.map((type) => (
                <SelectItem
                  className="mt-Select-option"
                  key={type.id} 
                  value={type.id}
                >
                  {type.title}
                </SelectItem>
              ))}
            </Select>
            {formik.touched.test_type_id && formik.errors.test_type_id && (
              <div className="text-red-500 text-sm">{formik.errors.test_type_id}</div>
            )}
          </>
        )}

        {/* Show provider selection in both modes, but only after test type is selected in add mode */}
        {(!addingTestType || formik.values.test_type_id !== -1) && (
          <>
            <Typography data-testid="pttf-provider-title" variant="h5" className="text-blue-500 mt-4">Provider</Typography>
            <Select
              data-testid="pttf-provider-select"
              className="mt-Select-button"
              label="Provider"
              value={formik.values.provider_type}
              onChange={handleProviderChange}
              onBlur={() => formik.setFieldTouched('provider_type', true)}
              error={formik.touched.provider_type && Boolean(formik.errors.provider_type)}
            >
              {availableProviders.map((provider) => (
                <SelectItem
                  className="mt-Select-option"
                  key={provider.id} 
                  value={provider.id}
                >
                  {provider.title}
                </SelectItem>
              ))}
            </Select>
            {formik.touched.provider_type && formik.errors.provider_type && (
              <div className="text-red-500 text-sm">{formik.errors.provider_type}</div>
            )}
          </>
        )}

        <Typography data-testid="pttf-api-url-title" variant="h5" className="text-blue-500 mt-4">API URL</Typography>
        <TextInput
          data-testid="pttf-api-url-input"
          label="API URL"
          name="api_url"
          placeholder="Enter the API URL"
          value={formik.values.api_url}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.api_url && formik.errors.api_url}
          errorMessage={formik.errors.api_url}
        />

        {currentConfig?.fieldsToShow.includes('api_key') && (
          <>
            <Typography data-testid="pttf-api-key-title" variant="h5" className="text-blue-500 mt-4">
              {currentConfig?.labels.api_key || "API Key"}
            </Typography>
            <EditableTextInput
              data-testid="pttf-api-key-input"
              label={currentConfig?.labels.api_key || "API Key"}
              placeholder={currentConfig?.helpText.api_key || "Enter an API key"}
              name="api_key"
              type="text"
              value={formik.values.api_key}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.api_key && formik.errors.api_key}
              errorMessage={formik.errors.api_key}
              setFieldValue={formik.setFieldValue}
              initialValue={formik.initialValues.api_key}
            />
          </>
        )}

        {currentConfig?.fieldsToShow.includes('api_username') && (
          <>
            <Typography data-testid="pttf-api-user-title" variant="h5" className="text-blue-500 mt-4">
              {currentConfig?.labels.api_username || "API Username"}
            </Typography>
            <TextInput
              data-testid="pttf-api-user-input"
              label={currentConfig?.labels.api_username || "API Username"}
              placeholder={currentConfig?.helpText.api_username || "Enter an API Username"}
              name="api_username"
              value={formik.values.api_username}
              error={formik.touched.api_username && formik.errors.api_username}
              errorMessage={formik.errors.api_username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </>
        )}

        {currentConfig?.fieldsToShow.includes('api_password') && (
          <>
            <Typography data-testid="pttf-api-password-title" variant="h5" className="text-blue-500 mt-4">
              {currentConfig?.labels.api_password || "API Password"}
            </Typography>
            <EditableTextInput
              data-testid="pttf-api-password-input"
              label={currentConfig?.labels.api_password || "API Password"}
              placeholder={currentConfig?.helpText.api_password || "Enter an API Password"}
              name="api_password"
              value={formik.values.api_password}
              type="text"
              error={formik.touched.api_password && formik.errors.api_password}
              errorMessage={formik.errors.api_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              initialValue={formik.initialValues.api_password}
            />
          </>
        )}

        {currentConfig?.fieldsToShow.includes('assets_url') && (
          <>
            <Typography data-testid="pttf-asset-url-title" variant="h5" className="text-blue-500 mt-4">
              {currentConfig?.labels.assets_url || "Assets Storage URL"}
            </Typography>
            <TextInput
              data-testid="pttf-asset-url-input"
              label={currentConfig?.labels.assets_url || "Assets Storage URL"}
              placeholder={currentConfig?.helpText.assets_url || "Enter the Azure Storage container URL"}
              name="assets_url"
              value={formik.values.assets_url}
              error={formik.touched.assets_url && formik.errors.assets_url}
              errorMessage={formik.errors.assets_url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </>
        )}

        <AlertManager 
          data-testid="pttf-alert-manager"
          successMessage={successMessage} 
          setSuccessMessage={setSuccessMessage} 
          errorMessage={errorMessage} 
          setErrorMessage={setErrorMessage} 
        />

        <Navbar data-testid="pttf-footer-bar" className="m-0 p-0 mt-5 flex items-center justify-end w-full p-4 gap-4 bg-gray-100">
          <div className="flex ml-auto gap-4">
            <Button 
              data-testid="pttf-cancel-button"
              color="blue" 
              variant="outlined" 
              onClick={onCancel}
              className="flex text-blue-500 items-center gap-3"
            >
              <FaBan /> Cancel
            </Button>
            <Button 
              data-testid="pttf-save-button"
              color="blue" 
              type="submit" 
              disabled={!formik.dirty && !formik.isSubmitting}
              className="flex items-center gap-3"
            >
              <FaSave /> Save
            </Button>
          </div>
        </Navbar>
      </form>
    </Card>
  );
};

ProjectTestTypesEditForm.propTypes = {
  acdId: PropTypes.number,
  addingTestType: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpdatedTestType: PropTypes.func,
  projectId: PropTypes.number,
};

export default ProjectTestTypesEditForm;