import React from 'react';

import { Card } from "@tremor/react";
import { Typography } from '@material-tailwind/react';

import AdminLayout from '../../layouts/AdminLayout.jsx';

export default function AdminTestTypes() {

  return (
    <AdminLayout>
      <div>
        <Card className="flex w-full h-full flex-col static">
            <h2>disQo Administration - Test Types</h2>
            <div className="flex md:flex-row flex-col items-start pt-4">
                <div className="md:order-2 order-1 w-full lg:w-1/6 md:w-1/5 flex-shrink-0 text-center m-3">
                    <img src="/assets/logo/tiqtoq.svg" className="h-auto w-1/4 md:w-full max-w-full block mx-auto" alt="tiQtoQ Logo" />
                </div>
                <Typography variant='paragraph' className="md:order-1 order-2 flex-grow">
                    Welcome to the disQo Dashboard, the essential tool for overseeing the quality of your digital products and releases. The platform offers a comprehensive suite of features for in-depth User Interface analysis, robust API monitoring, detailed performance metrics, thorough security audits, and accessibility compliance. Designed for efficiency and ease of use, the disQo Dashboard is your reliable solution in enhancing and securing your digital offerings with precision and expertise.
                </Typography>
            </div>
        </Card>
      </div>
      
    </AdminLayout>
  );
}