import React from 'react';

import AdminLayoutOrganisation from '../../layouts/AdminLayoutOrganisation';
import Users from '../../components/admin/users/Users';

export default function AdminUsers() {

  return (
    <AdminLayoutOrganisation>
      <div>
          <Users />
      </div>
      
    </AdminLayoutOrganisation>
  );
}