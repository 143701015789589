export const providerConfigs = {
    "-1": { // Default state when no provider is selected
      fieldsToShow: [],
      labels: {},
      helpText: {}
    },
    1: { // SauceLabs
      fieldsToShow: ['api_username', 'api_password', 'assets_url'],
      labels: {
        api_username: "SauceLab Username",
        api_password: "SauceLab Access Key", // NOSONAR - This is the label to use for the field, not the value
        assets_url: "Assets Storage Container URL"
      },
      helpText: {
        api_username: "Your Saucelabs username.",
        api_password: "Your Saucelabs access key.",  // NOSONAR - This is the label to use for the field, not the value
        assets_url: "The Azure Storage container URL where test assets will be stored."
      }
    },
    2: { // SonarQube
      fieldsToShow: ['api_key', 'api_password'],
      labels: {
        api_key: "API Key",
        api_password: "Component Key" // NOSONAR - This is the label to use for the field, not the value
      },
      helpText: {
        api_key: "Your SonarQube API key.",
        api_password: "Component key as required by SonarQube." // NOSONAR - This is the label to use for the field, not the value
      }
    },
    3: { // Azure Blob Storage
      fieldsToShow: ['api_key', 'assets_url'],
      labels: {
        api_key: "Storage Account Access Key",
        assets_url: "Container URL"
      },
      helpText: {
        api_key: "The access key for your Azure Storage Account.",
        assets_url: "The URL of the blob container where assets will be stored."
      }
    },
    4: { // Azure DevOps
      fieldsToShow: ['api_url', 'api_username', 'api_password', 'assets_url'],
      labels: {
        api_url: "Pipeline URL",
        api_username: "Username",
        api_password: "Password", // NOSONAR - This is the label to use for the field, not the value
        assets_url: "Assets Storage Container URL"
      },
      helpText: {
        api_username: "Your Azure DevOps username.",
        api_password: "Your Azure DevOps password.",  // NOSONAR - This is the label to use for the field, not the value
        assets_url: "The Azure Storage container URL where test assets will be stored."
      }
    }
  };