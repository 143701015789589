// FeatureIcon.js
import React from 'react';
import PropTypes from 'prop-types';
import { featureDetails } from '../../datahooks/metadata/DataUtils';

const FeatureIcon = ({ testTypeId, size = 25, colourClass = 'text-tremor-title' }) => {
  
  const feature = featureDetails[testTypeId] || featureDetails[0]; // Use default if not found

  const Icon = feature.icon;
  return <Icon className={`${colourClass} mr-2 dark:text-white-500`} size={size} title={feature.title} />;
};

FeatureIcon.propTypes = {
  testTypeId: PropTypes.number,
  size: PropTypes.number,
  colourClass: PropTypes.string,
};

export default FeatureIcon;