import React from "react";
import { Typography } from "@material-tailwind/react";
import PropTypes from 'prop-types';

export default function SpinningTitle({theTitle = 'Dashboard'}) {

    return (
            <Typography variant="h2" className="!normal-case !text-4xl" >
                dis<span data-testid='spinning-q' className="rotate-q !uppercase">Q</span>o {theTitle}
            </Typography>
    );
}

SpinningTitle.propTypes = {
    theTitle: PropTypes.string,
};