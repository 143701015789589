import React from 'react';
import FooterLink from './FooterLink';
import { Typography } from '@material-tailwind/react';

const Footer = () => {
  return (
    <footer className="border-t dark:border-dark-tremor-border mt-4 pb-8 min-h-80 bg-dark-blue-linear">

      <div className="mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8">
        
        <div className="flex flex-col items-center ml-10 mt-5 mb-5">
            <FooterLink hoverBright={false} url="https://tiqtoq.co.uk">
                <img 
                    src="/assets/logo/tiqtoq_rev.svg" 
                    className="sm:h-16 h-12 m-6 sm:m-8" alt="tiQtoQ Logo" />
            </FooterLink>
            <FooterLink className="ml-4 sm:ml-6" url="tel:+443333355754">
                <img className='mr-3' src="https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66c332308ff0e2250a446675_Phone%20receiver%20silhouette.svg" alt="Phone" />0333 3355754
            </FooterLink>
            <FooterLink className="ml-4 sm:ml-6" url="mailto:enquiries@tiqtoq.co.uk">
                <img className='mr-3' src='https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66c332313e80b0039a6a8635_Email.svg' alt="Email" />enquiries@tiQtoQ.co.uk
            </FooterLink>
            <div className="flex flex-row w-full justify-center space-x-7 mt-12 ml-4 sm:ml-6">
                <FooterLink url="https://www.linkedin.com/company/11032412">
                    <img src="https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66c332875884e7a8763cf8ab_Linkedin.svg" alt="LinkedIn" aria-label="LinkedIn"></img>
                </FooterLink>
                <FooterLink url="https://www.twitter.com/tiqtoqassurance">
                    <img src="https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66c33287d5478248efd1426f_Twitter.svg" alt="Twitter" aria-label='Twitter' />
                </FooterLink>
                <FooterLink url="https://www.facebook.com/tiQtoQSQA">
                    <img src="https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66c33287584b4aa9331330ed_Facebook%20App%20Symbol.svg" alt="Facebook" aria-label='Facebook' />
                </FooterLink>
                <FooterLink url="https://www.threads.net/@tiqtoqsoftwaretesting">
                    <img src="https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66c33288318806070015171d_Threads.svg" alt="Threads" aria-label='threads' />
                </FooterLink>
            </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-16 mb-5 gap-y-5">
            <Typography variant='paragraph' className="text-white-paragraph">Copyright © 2024 tiQtoQ Ltd</Typography>
            <Typography variant='paragraph' className="text-white-paragraph">Registered Office: Mornington, Drope Road, Cardiff, CF5 6EP</Typography>
            <Typography variant='paragraph' className="text-white-paragraph">Company Number: 10168834</Typography>
            <Typography variant='paragraph' className="text-white-paragraph">VAT Number: GB265586661</Typography>
            <div className="flex flex-row w-full justify-center mt-5">
                <img
                    className="w-32 h-auto max-w-none object-contain mr-3"
                    src="https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66d817dc446687e534dfa3a9_fast-growth.png" 
                    alt="Fast Growth 50" />
                <img 
                    className="w-20 h-auto max-w-none object-contain mr-3"
                    src="https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66c338372770a38e34ee549c_cyber-essentials-logo.png" 
                    alt="Cyber Essentials Logo" />
                <img 
                    className="w-20 h-auto max-w-none object-contain"
                    src="https://cdn.prod.website-files.com/66bc7d519ed30aa6ba2a1b77/66e168937b3211fdc314a2a2_image%2036.png" 
                    alt="ISO 27001 Logo" />
                </div>
        </div>
        </div>
    </footer>
  );
};

export default Footer;