import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Select, SelectItem } from "@tremor/react";
import { Typography, Button, Navbar } from '@material-tailwind/react';
import { FaSave, FaBan } from 'react-icons/fa';

const UserProjectAddForm = ({ availableProjects = [], onCancel, onUpdateProjectAccess }) => {
  const [selectedProjectId, setSelectedProjectId] = useState("");

  const handleSelectChange = (event) => {
    setSelectedProjectId(event);
  };

  const handleSaveClick = () => {
    if (selectedProjectId) {
      onUpdateProjectAccess(selectedProjectId);
    }
  };

  return (
    <Card className="flex flex-col w-full h-full text-blue-500 items-center">
      <Typography variant="h5" className="text-blue-500 mt-2">Grant Project Access</Typography>
      
      <Select
        className='mt-Select-button'
        label="Project"
        value={selectedProjectId}
        onChange={handleSelectChange}
      >
        {availableProjects?.map((project) => (
          <SelectItem className='mt-Select-option' key={project.project_id} value={project.project_id}>
            {project.short_name}
          </SelectItem>
        ))}
      </Select>

      <Navbar className="m-0 p-0 mt-5 flex items-center justify-end w-full p-4 gap-4 bg-gray-100">
        <div className="flex ml-auto gap-4">
          <Button 
            color="blue" 
            variant="outlined" 
            onClick={onCancel}
            className="flex text-blue-500 items-center gap-3"
          >
            <FaBan /> Cancel
          </Button>
          <Button 
            color="blue" 
            type="button" 
            disabled={!selectedProjectId}
            onClick={handleSaveClick}
            className="flex items-center gap-3"
          >
            <FaSave /> Save
          </Button>
        </div>
      </Navbar>
    </Card>
  );
};

UserProjectAddForm.propTypes = {
  availableProjects: PropTypes.array,
  onCancel: PropTypes.func,
  onUpdateProjectAccess: PropTypes.func,
};

export default UserProjectAddForm;
