import { Button, Navbar } from "@material-tailwind/react";
import PropTypes from 'prop-types';
import { HiMenu } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import SideBar from '../components/navigation/SideBar.jsx';

import { useAuth } from '../components/navigation/Authentication.jsx';
import useUser from '../datahooks/useUser.js';
import { isDarkMode } from '../datahooks/metadata/DataUtils.js';

import SpinningTitle from "../components/general/SpinningTitle.jsx";
import UserMenu from "../components/navigation/UserMenu.jsx";
import Footer from "../components/navigation/Footer.jsx";
import LoadingIcon from "../components/navigation/LoadingIcon.jsx";
import { useNavigate, Link } from 'react-router-dom';

function AdminLayout({ children, superAdminOnly = false }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { isInitialised, signOut } = useAuth();
    const { user: userDetails } = useUser();

    const navigate = useNavigate();

    useEffect(() => {
        if (!userDetails && isInitialised) {
            navigate('/'); // Redirect to login if not authenticated
        } else if (superAdminOnly && userDetails?.user_type !== 'super_admin') {
            navigate('/home'); // Redirect home if not super_admin
        } else if (!superAdminOnly && userDetails?.user_type !== 'admin' && userDetails?.user_type !== 'super_admin') {
            navigate('/home'); // Redirect home if not admin or super_admin
        }
    }, [userDetails, isInitialised, navigate, superAdminOnly]);


    if (!isInitialised || !userDetails) {
        // Show loading icon while checking user authentication
        return <LoadingIcon size={150} showText />;
    }

    return (
        <div className='dark:bg-dark-tremor-background-muted bg-white'>
            <Navbar className="flex dark:bg-dark-tremor-background-muted items-center justify-between top-0 relative w-full max-w-full rounded-none px-2 py-2 my-1 lg:px-2 lg:py-1 border-b dark:border-dark-tremor-border "
                    style={{ position: 'relative', zIndex: '21' }}>
                <div className="flex items-center flex-shrink-0 m-4 sm:m-6">
                    <Link to={"/home"}>
                    {isDarkMode() ? (
                        <img src="/assets/logo/tiqtoq_rev.svg" className="sm:h-12 h-8" alt="tiQtoQ Logo" />
                    ) : (
                        <img src="/assets/logo/tiqtoq.svg" className="sm:h-12 h-8" alt="tiQtoQ Logo" />
                    )}
                    </Link>
                </div>
                
                <SpinningTitle className="hidden md:block flex-grow" theTitle="Administration" />

                <div className="flex items-center flex-shrink-0">
                    <UserMenu doLogout={signOut} isAdmin />
                    <Button  className='md:hidden ml-3' variant="outlined" onClick={() => setDrawerOpen(!drawerOpen)}>
                        <HiMenu className="h-5 w-5 p-0 m-0" />
                    </Button>
                </div>
            </Navbar>

            <div className="flex flex-col min-h-screen">
                <div className="flex flex-grow">
                    <SideBar handleClick={setDrawerOpen} drawerOpen={drawerOpen} isAdmin />
                    <div className="flex-grow overflow-auto m-3">
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

AdminLayout.propTypes = {
    children: PropTypes.node,
    superAdminOnly: PropTypes.bool,
};

AdminLayout.defaultProps = {
    superAdminOnly: false,
};

export default AdminLayout;