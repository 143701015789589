import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';

export default function useProjectTestTypes(projectId = null) {
  const { user:userDetails } = useUser();

  const fetchProjectTestTypes = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/project_test_types?project_id=${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });
      return response.data;
    } catch (error) {
      logError('Failed to load project test types in useProjectTestTypes', error, {}); // Error during fetch
      throw error;
    }
  };
  
  const { data: projectTestTypesData = [], isLoading, refetch, error } = useAuthenticatedQuery(`project_test_types_${userDetails?.id}_${projectId}`, fetchProjectTestTypes, [PROJECT_SCOPE], {
    staleTime: Infinity, // The data will never be considered stale. If the result is in a pending state, a refetch button will be available
  });
  
  return { projectTestTypesData, projectTestTypesLoading: isLoading, refetchProjectTestTypes: refetch, projectTestTypesError: error };
}

useProjectTestTypes.propTypes = {
  projectId: PropTypes.number
}
