import React, { useState } from "react";
import ProjectsTable from "./ProjectsTable";
import { Navbar, Button } from '@material-tailwind/react';
import { IoAddCircle } from "react-icons/io5";

import { useOrgContext } from '../../../datahooks/admin/organisation/AdminOrgIDContext.jsx';
import useProjectLists from "../../../datahooks/admin/project/useProjectLists";
import useProjectManager from '../../../datahooks/admin/project/useProjectManager.js';

import LoadingIcon from "../../navigation/LoadingIcon";
import ProjectEditForm from "./ProjectEditForm";
import AlertManager from "../../general/AlertManager.jsx";

export default function Projects() {
    const { organisationData } = useOrgContext();
    const { projectsData, loadingProjects, projectsError, refetchProjects } = useProjectLists(organisationData?.id);

    const [ editingProject, setEditingProject ] = useState(-1);
    const [ addingProject, setAddingProject ] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const projectUpdated = (aResponse) => {
        refetchProjects();
        setSuccessMessage('Project updated successfully.');
    }

    const projectSaveFailed = (error) => {
        setErrorMessage(`Failed to save project: ${error?.errorResponse?.message || 'Unknown error'}`);
    }
    
    const { performAction } = useProjectManager(projectUpdated, projectSaveFailed);

    const handleAddProject = () => {
        setAddingProject(true);
    }

    const handleDeleteProject = async (projectId, newStatus) => {
        const payload = {}; // Initialise payload
        payload.project_id = projectId; // Set the project_id

        if (newStatus === 'inactive') {
            await performAction(payload, 'DELETE');
        } else {
            payload.new_project_status = 'active';
            await performAction(payload, 'POST')
        }
    }

    if (loadingProjects) {
        return <LoadingIcon showText />
    }

    if (projectsError) {
       return <div>Error: {projectsError}</div>
    }


  return (
    <div className="flex flex-col w-full h-full text-blue-500 items-center p-4">
        <div className="w-full max-w-screen-2xl flex items-center justify-center flex-col">
            {addingProject && <ProjectEditForm 
                                addingProject={addingProject} 
                                onCancel={() => { setAddingProject(false)} } 
                                onUpdatedProject={() => refetchProjects()}
                                organisationId={organisationData?.id}
                                />}

            {editingProject !== -1 && <ProjectEditForm 
                                        projectId={editingProject} 
                                        onCancel={() => { setEditingProject(-1)} }
                                        onUpdatedProject={() => refetchProjects()}
                                    />}

            {!addingProject && editingProject === -1 && (
                <ProjectsTable 
                    projectsData={projectsData} 
                    onEditProject={(project) => setEditingProject(project)}
                    onDeleteProject={(projectId, newStatus) => handleDeleteProject(projectId, newStatus)}
                />
            )}

            <AlertManager 
                className="m-2"
                successMessage={successMessage} 
                setSuccessMessage={setSuccessMessage} 
                errorMessage={errorMessage} 
                setErrorMessage={setErrorMessage} 
            />

            {(!addingProject && editingProject === -1) && (
            <Navbar className='m-0 p-0 mt-5 flex items-center justify-between w-full p-4 gap-3 bg-gray-100'>
                <div className='flex gap-3'>
                    <Button 
                        size="sm" 
                        variant="outlined" 
                        className="flex text-blue-500 items-center gap-3"
                        onClick={() => handleAddProject()}
                        disabled={addingProject || editingProject !== -1}
                    >
                        <IoAddCircle size={20} /> Add New
                    </Button>
                </div>
            </Navbar>
            )}
        </div>
    </div>
  );
}