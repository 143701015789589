import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, IconButton, Typography } from '@material-tailwind/react';
import { FaEdit } from 'react-icons/fa';
import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard';
import AvatarLogo from '../../general/AvatarLogo';
import DisableDialog from '../general/DisableDialog';

const ProjectsTable = ({ projectsData = [], onDeleteProject, onEditProject }) => {
  const [showActiveProjects, setShowActiveProjects] = useState(true);

  const handleToggleProjects = () => {
    setShowActiveProjects(!showActiveProjects);
  };

  const filteredProjects = projectsData?.filter((project) => 
    showActiveProjects ? project.project_status === 'active' : project.project_status !== 'active'
  );

  return (
    <div className="w-full">
        <div className="flex justify-between items-center mb-4">
            <h2>Projects</h2>
            <Switch
                color="blue"
                className='ml-auto'
                label={`Show ${showActiveProjects ? 'Active' : 'Inactive'} Projects`}
                checked={showActiveProjects}
                onChange={handleToggleProjects}
            />
        </div>

      {filteredProjects.length === 0 ? (
        <div className="flex flex-col justify-center items-center p-4">
            <ErrorCard cardType={CARD_TYPES.EMPTY} />
            <Typography variant='h6'>No projects found. Add a new one to get started.</Typography>
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          {filteredProjects.map((project) => (
            <div className="flex justify-between items-center p-2" key={project.id}>
                <div className="w-1/12">
                        <AvatarLogo src={project.logo_location} alt={`${project.short_name} logo`} size='xs' initialsCount={2} displayType='logo' />
                </div>
              
                <div className="w-3/4">{project.title}</div>
              
                <div className="w-1/6 flex justify-end gap-2">

                    {project.project_status == 'active' && <IconButton 
                        color='blue' 
                        variant='text'
                        alt={`Edit project?`}
                        onClick={() => onEditProject(project.id)}
                    >
                        <FaEdit size={22} />
                    </IconButton>
                    }
                    <DisableDialog 
                        theId={project.id}
                        theStatus={project.project_status}
                        theType='Project'
                        showText={false}
                        onConfirm={onDeleteProject}
                    />
                </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
ProjectsTable.propTypes = {
  projectsData: PropTypes.array,
  onDeleteProject: PropTypes.func,
  onEditProject: PropTypes.func,
};
export default ProjectsTable;
