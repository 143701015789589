import React from 'react';
import { HiHome } from 'react-icons/hi';
import { List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import TextPlaceholder from "../general/TextPlaceholder";
import PropTypes from 'prop-types';

import useProjects from '../../datahooks/useProjects';
import AvatarLogo from '../general/AvatarLogo';

/**
 * This component is used to generate the links for the menu.
 * The links are loaded from the projects file using the useProjects hook.
 * This allows us to include the same links in the drawer and sidebar component,
 * drawer for mobile, sidebar for desktop.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Boolean} props.inDrawer - Whether the menu is being displayed in a drawer.
 * @param {Boolean} props.isCollapsed - Whether the sidebar is collapsed or expanded.
 * @returns {JSX.Element} The rendered list of menu links.
 */
const MenuLinks = ({ inDrawer = false, isCollapsed = false }) => {
  const { projects, isLoading } = useProjects();

  return (
    <List data-testid='menulinks' className='min-w-full p-1'>
      <Link to={"/home"}>
        <ListItem data-testid='menulinks-home' className='text-blue-500'>
          <ListItemPrefix 
            title={isCollapsed && !inDrawer ? "Home" : ""}
            aria-label={isCollapsed && !inDrawer ? "Home" : undefined}>
            <HiHome className="h-10 w-10" />
          </ListItemPrefix>
          
          <Typography 
            variant='paragraph' 
            className={`${inDrawer || !isCollapsed ? 'block' : 'hidden'} transition-opacity duration-300`} 
            color='blue'>
              Home
          </Typography>
        </ListItem>
      </Link>
      <hr className="my-2 border-blue-gray-100 dark:border-dark-tremor-border" />
      {isLoading || !projects ? (
        <TextPlaceholder lines={5} random={false} />
      ) : (
        projects.map(project => (
          <Link data-testid={`menulinks-project-${project.projectId}`} key={project.short_name} to={`/projects/${project.projectId}`}>
            <ListItem className='text-blue-500'>
              <ListItemPrefix 
                title={isCollapsed && !inDrawer ? project.title : ""}
                aria-label={isCollapsed && !inDrawer ? project.title : undefined}>
                <AvatarLogo
                  alt={project.short_name}
                  initialsCount={3}
                  className="w-10 h-10"
                />
              </ListItemPrefix>
              <Typography 
                variant='paragraph' 
                className={`${inDrawer || !isCollapsed ? 'block' : 'hidden'} transition-opacity duration-300`} 
                color='blue'>
                  {project.short_name}
              </Typography>
            </ListItem>
          </Link>
        ))
      )}
    </List>
  );
}

MenuLinks.propTypes = {
  inDrawer: PropTypes.bool,
  isCollapsed: PropTypes.bool,
};

export default MenuLinks;