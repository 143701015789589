import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';
import logError from '../../metadata/ErrorLogging.js';

export default function useProjectLists(orgId = null) {
  const { user:userDetails } = useUser();

  const fetchProjects = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/projects?org_id=${orgId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });
      return response.data;
    } catch (error) {
      logError('Failed to load projects in useProjectLists', error, {}); // Error during fetch
      throw error;
    }
  };
  
  const { data: projectsData = [], isLoading, refetch, error } = useAuthenticatedQuery(`projects_${userDetails?.id}_${orgId}`, fetchProjects, [PROJECT_SCOPE], {
    staleTime: 300000, //Stale time of 5 minutes
  });

  return { projectsData, loadingProjects: isLoading, refetchProjects: refetch, projectsError: error };
}

useProjectLists.propTypes = {
  orgId: PropTypes.number
}