import React from 'react';

import AdminLayoutOrganisation from '../../layouts/AdminLayoutOrganisation.jsx';

import EditOrganisation from '../../components/admin/organisation/EditOrganisation.jsx';

export default function AdminOrganisation() {
  
  return (
    <AdminLayoutOrganisation>
      <div>
          <EditOrganisation />
      </div>
    </AdminLayoutOrganisation>
  );
}