import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import AvatarLogo from '../../general/AvatarLogo';
import { Button } from '@material-tailwind/react';
import { useSasToken, useUploadImage } from '../../../datahooks/admin/imageUploadHooks';

import { FaImage } from 'react-icons/fa';

const ImageUploader = forwardRef(({ imgSrc, onSavePath, imageType, imageId, onImageSelect, displayType }, ref) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(imgSrc);
    const fileInputRef = useRef(null);
    
    const { sasToken, isLoading: isTokenLoading, error: isTokenError, triggerFetchToken } = useSasToken();
    const { mutate, isLoading: isUploading, error, uploadProgress } = useUploadImage(onSavePath);

    useImperativeHandle(ref, () => ({
        uploadImage: async () => {
            if (!selectedFile) {
                console.error('No file selected for upload');
                return;
            }
            if (!sasToken && !isError && !isTokenLoading) {
                triggerFetchToken();  // Fetch token when needed, before attempting to upload
                return;  // Do not proceed to upload until the token is fetched
            }
            if (isTokenError) {
                console.error('Error fetching SAS token');
                return;
            }
            mutate({
                file: selectedFile,
                sasToken,
                type: imageType,
                id: imageId
            });
        },
        resetImage: (clearAll = false) => {
            setSelectedFile(null);
            setPreviewUrl(clearAll ? '' : imgSrc); // Clear the preview if clearAll is true, otherwise show the original image
            fileInputRef.current.value = '';  // Clear the input field
        }
    }));

    useEffect(() => {
        if (previewUrl !== imgSrc) {
            setPreviewUrl(imgSrc); // Ensure previewUrl is set to imgSrc
        }
      }, [imgSrc]);

    const handleImageChange = event => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
            onImageSelect(file);
        }
    };

    return (
        <div className='flex  gap-3'>
            <AvatarLogo src={previewUrl} size="lg" displayType={displayType} />
            <input type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef} style={{ display: 'none' }} />
            <div className="flex items-center">
                <Button color="blue" className="h-auto flex items-center gap-3" size="sm" onClick={() => fileInputRef.current.click()}>
                    <FaImage size={20} /> Choose Image
                </Button>   
            </div>
            
            {isTokenError && <p>Error fetching SAS token.</p>}
            {isUploading && <p>Uploading... {uploadProgress.toFixed(2)}%</p>}
            {error && <p>Error uploading image: {error.message}</p>}
        </div>
    );
});
ImageUploader.propTypes = {
    imgSrc: PropTypes.string,
    onSavePath: PropTypes.func,
    imageType: PropTypes.string,
    imageId: PropTypes.number,
    onImageSelect: PropTypes.func,
    displayType: PropTypes.string
};

export default ImageUploader;
