//Project manager provides a hook that performs different mutations on 
//project data and handles multiple methods for mutating the data.
import useAuthenticatedMutation from '../useAuthenticatedMutation';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config';
import logError from '../../metadata/ErrorLogging';

// Function to perform HTTP requests
const manageProject = async ({data, method}, token) => {
    let url = `${API_BASE_URL}disqoadmin/project`;

    if (method === 'DELETE' && data.project_id) {
        url += `?project_id=${data.project_id}`; // Add to URL for DELETE
    }

    try {
        const response = await axios({
            method: method,
            url: url,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-functions-key': FUNCTION_KEY,
            },
            data: method !== 'DELETE' ? data : {}, // Only send data for non-DELETE requests
        });

        return response.data;
    } catch (error) {
        logError(`Failed to ${method.toLowerCase()} project:`, error, {});
        throw (error?.response?.data) ? error.response.data : error;
    }
};

// Hook to manage organisations
export default function useProjectManager(onSuccess, onError) {
    
    const projectScope = [PROJECT_SCOPE];
    const { mutate, isLoading, isError, error, data, reset: resetMutation } = useAuthenticatedMutation(manageProject, projectScope, {
        onSuccess,
        onError
    });

    // Function to call the mutate function with additional parameters for different HTTP methods
    const performAction = (data, method = 'POST') => {
        return new Promise((resolve, reject) => {
            mutate(
                { data, method },
                {
                    onSuccess: (result) => {
                        resolve(result);
                        if (onSuccess) onSuccess(result);
                    },
                    onError: (error) => {
                        reject(error);
                        if (onError) onError(error);
                    },
                }
            );
        });
    };

    return {
        performAction,
        isLoading,
        isError,
        error,
        data,
        reset: resetMutation
    };
}

useProjectManager.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func
}