import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import logError from '../../metadata/ErrorLogging.js';

export default function useTestTypeProviders() {
  const fetchProviders = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/test_type_providers`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });

      return response.data;
    } catch (error) {
      logError('Failed to load providers in useTestTypeProviders', error, {});
      throw error;
    }
  };
  
  const { data: providersData = [], isLoading, refetch, error } = useAuthenticatedQuery(
    'test_type_providers', 
    fetchProviders, 
    [PROJECT_SCOPE], 
    {
      staleTime: Infinity
    }
  );

  // Helper function to get providers for a specific test type
  const getProvidersForTestType = (testTypeId) => {
    if (!testTypeId || !providersData) return [];

    return providersData.filter(mapping => mapping.test_type_id === testTypeId)
      .map(mapping => ({
        id: mapping.provider_id,
        title: mapping.provider_title
      }));
  };

  return { 
    providersData,
    getProvidersForTestType, 
    loadingProviders: isLoading, 
    refetchProviders: refetch, 
    providersError: error 
  };
}