// LoadingIcon.js
import React from 'react';
import PropTypes from 'prop-types';
function LoadingIcon({ size = 150, label = "Loading...", showText = false }) {
  // Calculate font size and image size as a fraction of the container size
  const fontSize = size / 6;
  const imageSize = size / 3;

  const containerStyle = {
    width: `${size}px`,
    height: `${size}px`,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const labelStyle = {
    fontSize: `${fontSize}px`,
    paddingTop: 5,
    animation: 'fade 1.5s infinite', // Add animation
  };

  return (
    <div style={containerStyle}>
      <img
        src="/assets/logo/tiqtoq_loader.svg"
        alt="Loading..."
        width={imageSize}
        height={imageSize}
      />
      {showText && <div style={labelStyle} className='text-blue-500'>{label}</div>}
    </div>
  );
}

LoadingIcon.propTypes = {
  size: PropTypes.number,
  label: PropTypes.string,
  showText: PropTypes.bool,
};

export default LoadingIcon;