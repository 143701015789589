import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';
import logError from '../../metadata/ErrorLogging.js';

export default function useTestTypes(shouldFetch = true) {
  const { user:userDetails } = useUser();

  const fetchTestType = async (token) => {
    if (!shouldFetch) return []; // Immediately return empty array if not supposed to fetch
    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/test_types`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });

      return response.data;

    } catch (error) {
      logError('Failed to load test types in useTestTypes', error, {}); // Error during fetch
      throw error;
    }
  };
  
  const { data: testTypesData = [], isLoading, refetch, error } = useAuthenticatedQuery(`test_types_${userDetails?.id}`, fetchTestType, [PROJECT_SCOPE], {
    staleTime: Infinity,
    enabled: shouldFetch,
  });
  
  return { testTypesData, loadingTestTypes: isLoading, refetchTestTypes: refetch, testTypesError: error };
}

useTestTypes.propTypes = {
  shouldFetch: PropTypes.bool
}