import { Navbar, Button as MTButtton } from "@material-tailwind/react";
import PropTypes from 'prop-types';
import { HiMenu } from 'react-icons/hi';
import { useEffect, useState } from 'react';

import SideBar from '../components/navigation/SideBar.jsx';

import { useAuth } from '../components/navigation/Authentication.jsx';
import useUser from '../datahooks/useUser.js';

import SpinningTitle from "../components/general/SpinningTitle.jsx";
import UserMenu from "../components/navigation/UserMenu.jsx";
import Footer from "../components/navigation/Footer.jsx";
import LoadingIcon from "../components/navigation/LoadingIcon.jsx";

import { useNavigate, Link } from 'react-router-dom';
import OrganisationMenuBar from "../components/admin/organisation/OrganisationMenuBar.jsx";

function AdminLayoutOrganisation({ children }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { isInitialised, signOut } = useAuth();
    const { user: userDetails } = useUser();

    const navigate = useNavigate();

    useEffect(() => {
        if (!userDetails && isInitialised) {
            navigate('/'); // Redirect to login if not authenticated
        }
        
        if (userDetails?.user_type !== 'admin' && userDetails?.user_type !== 'super_admin') {
            navigate('/home'); // Redirect to home if not admin
        }
    }, [ userDetails, isInitialised, navigate]);


    if (!isInitialised || !userDetails) {
        // Show loading icon while checking user authentication
        return <LoadingIcon size={150} showText />;
    }

    return (
        <div className='dark:bg-dark-tremor-background-muted bg-white'>
            <Navbar className="flex items-center dark:bg-dark-tremor-background-muted justify-between top-0 relative w-full max-w-full rounded-none px-2 py-2 my-1 lg:px-2 lg:py-1 border-b dark:border-dark-tremor-border "
                    style={{ position: 'relative', zIndex: '21' }}>
                <div className="flex items-center flex-shrink-0 sm:m-6 m-4">
                    <Link to={"/home"}>
                        <img src="/assets/logo/tiqtoq.svg" className="sm:h-12 h-8" alt="tiQtoQ Logo" />
                    </Link>
                </div>
                
                <SpinningTitle className="hidden md:block flex-grow" theTitle="Administration" />

                <div className="flex items-center flex-shrink-0">
                    <UserMenu doLogout={signOut} isAdmin />
                    <MTButtton  className='md:hidden ml-3' variant="outlined" onClick={() => setDrawerOpen(!drawerOpen)}>
                        <HiMenu className="h-5 w-5 p-0 m-0" />
                    </MTButtton>
                </div>
            </Navbar>

            <div className="flex flex-col min-h-screen">
                <div className="flex flex-grow">
                    <SideBar handleClick={setDrawerOpen} drawerOpen={drawerOpen} isAdmin />
                    <div className="flex-grow overflow-auto m-3">
                        <OrganisationMenuBar />
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

AdminLayoutOrganisation.propTypes = {
    children: PropTypes.node,
};

export default AdminLayoutOrganisation;