import PopExplanation from './PopExplanation';
import PropTypes from 'prop-types';
export default function PopCompatibility({ page = false, size=35 }) {

    return (
        <PopExplanation
            title="Compatibility"
            size={size}
            metric1Title={page ? "" : "Pass Rate"}
            metric1Desc={page ? "Ensures users can access and utilise the software or hardware, and that it performs as expected across various environments, devices, or operating system." : "Indicates the proportion of successful tests. A high pass rate indicates strong compatibility, while a low rate may highlight issues that need attention."}
            metric2Title={page ? "" : "Total Tests"}
            metric2Desc={page ? "" : "Shows the total number of executed tests, reflecting the extent of testing coverage and variety of operating systems, browsers and devices tested."} />
    );
}

PopCompatibility.propTypes = {
    page: PropTypes.bool,
    size: PropTypes.number
}