import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@material-tailwind/react';

import useUser from '../../datahooks/useUser.js'

import AvatarLogo from '../general/AvatarLogo.jsx';
import { FaRegUser } from "react-icons/fa";

export default function UserMenu({doLogout, isAdmin = false}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    
    const { user: userDetails } = useUser();
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <div className="relative" data-testid='usermenu'>
            
            <Button 
                onClick={toggleDropdown} 
                variant="text" 
                className="cursor-pointer p-1 hover:bg-transparent focus:bg-transparent active:bg-transparent"
            >
                {userDetails?.display_name ? 
                    (
                        <AvatarLogo 
                            src={userDetails?.image_url} 
                            alt={userDetails?.display_name}
                            size="lg"
                            initialsCount={2}
                            withBorder={true}
                        />
                    ) :
                        <FaRegUser size={40} />
                    }
            </Button>
            {isDropdownOpen && (
                <div className="absolute right-0 mt-2 py-2 w-48 bg-white z-50 rounded-lg shadow-xl">
                    <Link to="/user" className="block px-4 py-2 text-blue-500 hover:bg-gray-100 cursor-pointer">
                        User Profile
                    </Link>

                    {userDetails?.user_type === 'super_admin' && (  //Temporarily only show FAQs to super_admin users until content is ready
                        <Link to="/faq" className="block px-4 py-2 text-blue-500 hover:bg-gray-100 cursor-pointer">
                            FAQs
                        </Link>
                    )}
                    
                    <hr className="my-2 border-blue-gray-50" />

                    {/* Render the Data Collector API link for both admin and super_admin users */}
                    {userDetails?.user_type === 'super_admin' || userDetails?.user_type === 'admin' ? (
                        <Link to="/api" className="block px-4 py-2 text-blue-500 hover:bg-gray-100 cursor-pointer">
                            Data Collector API
                        </Link>
                    ) : null}

                    {/* Render the Dashboard API link for super_admin users only */}
                    {userDetails?.user_type === 'super_admin' ? (
                        <Link to="/apidashboard" className="block px-4 py-2 text-blue-500 hover:bg-gray-100 cursor-pointer">
                            Dashboard API
                        </Link>
                    ) : null}

                    <hr className="my-2 border-blue-gray-50" />

                    {/* Render Dashboard link based on isAdmin prop */}
                    {isAdmin ? (
                        <Link to="/" className="block px-4 py-2 text-blue-500 hover:bg-gray-100 cursor-pointer">
                            Dashboard
                        </Link>
                    ) : (
                        <Link to="/admin" className="block px-4 py-2 text-blue-500 hover:bg-gray-100 cursor-pointer">
                            Admin
                        </Link>
                    )}

                    <hr className="my-2 border-blue-gray-50" />
                    <Link onClick={doLogout} className="block px-4 py-2 text-blue-500 hover:bg-gray-100 cursor-pointer">
                        Logout
                    </Link>
                </div>
            )}
        </div>
    );
}

UserMenu.propTypes = {
    doLogout: PropTypes.func,
    isAdmin: PropTypes.bool,
};