import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { TextInput, Select, SelectItem } from "@tremor/react";
import { Typography, Button, Navbar } from '@material-tailwind/react';
import { FaSave, FaBan } from 'react-icons/fa';

import AlertManager from '../../general/AlertManager.jsx';
import _ from 'lodash';

import ImageUploader from '../general/ImageUploader.jsx';
import UserProjectsDialog from './UserProjectsDialog.jsx';
import UserPasswordDialog from './UserPasswordDialog.jsx';

import useEditUser from '../../../datahooks/admin/user/useEditUser.js';
import useUserManager from '../../../datahooks/admin/user/useUserManager.js';
import useUser from '../../../datahooks/useUser.js';

import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard.jsx';

import { userValidationSchema, MAX_LENGTH_DISPLAY_NAME, MAX_LENGTH_EMAIL, MAX_LENGTH_GIVEN_NAME, MAX_LENGTH_SURNAME } from '../../../datahooks/metadata/ValidationUtils.js';

const UserEditForm = ({ userId, addingUser, onCancel, onUpdatedUser, organisationId }) => {
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [temporaryPassword, setTemporaryPassword] = useState('');

  const imageUploaderRef = useRef(null);
  const [imageSelected, setImageSelected] = useState(false);

  const { user: adminUserDetails, refetch: refetchCurrentUser } = useUser();

  const userUpdated = (aResponse) => {
    setSuccessMessage("User updated successfully.");
    if (addingUser) {
      // Display temporary password if it was generated
      if (aResponse?.tempPass) {
        setTemporaryPassword(aResponse?.tempPass || '');
        setIsPasswordDialogOpen(true);
      }
    } else {
      if (imageSelected) {
        setImageSelected(false);
      }
      refetchEditUser();
      if (adminUserDetails?.id === userId) {
        refetchCurrentUser();
      }
      onUpdatedUser();
    }
  }

  const userSaveFailed = (error) => {
    setErrorMessage(`Failed to save user: ${error.message || 'Unknown error'}`);
  }

  const { performAction } = useUserManager(userUpdated, userSaveFailed);

  const handleCloseDialog = () => {
    setIsPasswordDialogOpen(false);
    setTemporaryPassword('');
    onUpdatedUser();
  };

  // Always call the hook and handle the condition inside the hook
  const { editUserData, loadingEditUser: editUserLoading, refetchEditUser, editUserError } = useEditUser(userId, addingUser);

  const [initialValues, setInitialValues] = useState({
    displayName: '',
    givenName: '',
    surname: '',
    email: '',
    image_url: null,
    user_type: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Set initial values based on editing or adding a new project
  useEffect(() => {
    const getDefaultInitialValues = () => ({
      displayName: '',
      givenName: '',
      surname: '',
      email: '',
      image_url: null,
      user_type: '',
    });

    if (addingUser) {
      const newInitialValues = getDefaultInitialValues();

      // Only update if values have changed to prevent rerenders
      if (!_.isEqual(initialValues, newInitialValues)) {
        setInitialValues(newInitialValues);
      }
    } else if (editUserData) {
      const newInitialValues = {
        displayName: editUserData.display_name || '',
        givenName: editUserData.given_name || '',
        surname: editUserData.surname || '',
        email: editUserData.email_address || '',
        image_url: editUserData.image_url || null,
        user_type: editUserData.user_type || '',
      };

      // Only update if values have changed to prevent rerenders
      if (!_.isEqual(initialValues, newInitialValues)) {
        setInitialValues(newInitialValues);
        imageUploaderRef?.current?.resetImage();
      }
    }
  }, [addingUser, editUserData]);

  const handleSubmit = async (values) => {
    // Validate the form
    try {
      await userValidationSchema.validate(values, { abortEarly: false });

      if (imageSelected) {
        imageUploaderRef.current.uploadImage(); // Call upload function if image is selected
      } else {
        // Save form data if no image upload is needed
        await doSave();  // Make sure doSave is awaited
      }
    } catch (error) {
      // If the form is invalid, capture and set the validation errors
      const validationErrors = error.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      formik.setErrors(validationErrors);
    }
  };

  const doSave = async (imagePath = null) => {
    if (addingUser) {
      // Required fields for a new project
      if (!formik.values.user_type || !formik.values.email || !formik.values.displayName) {
        console.error("Email, display name and user type are required.");
        return; // Return early if required fields are missing
      }

      await doAddUser(imagePath);

    } else {

      await doUpdateUser(imagePath);

    }
  }
    
  const doAddUser = async (imagePath) => {
    const payload = {
      "new_org_id": organisationId,
      "new_email_address": formik.values.email,
      "new_display_name": formik.values.displayName,
      "new_user_type": formik.values.user_type,
    };

    // Optional fields
    if (formik.values.givenName) {
      payload.new_given_name = formik.values.givenName;
    }

    if (imagePath) {
      payload.new_image_url = imagePath;  // If image is uploaded
    }

    if (formik.values.user_status) {
      payload.new_user_status = formik.values.user_status;
    }

    if (formik.values.surname) {
      payload.new_surname = formik.values.surname;
    }

    // Perform the action for adding a new project
    return performAction(payload, 'PUT');

  } 
  
  const doUpdateUser = async (imagePath) => {

    // Prepare the payload with only the fields that have changed
    const updatePayload = { "edit_user_id": userId };

    // Update only if values have changed
    if (!_.isEqual(initialValues.displayName, formik.values.displayName)) {
      updatePayload.new_display_name = formik.values.displayName;
    }

    if (!_.isEqual(initialValues.surname, formik.values.surname)) {
      updatePayload.new_surname = formik.values.surname;
    }

    if (!_.isEqual(initialValues.givenName, formik.values.givenName)) {
      updatePayload.new_given_name = formik.values.givenName;
    }

    if (imagePath && initialValues.new_image_url !== imagePath) {
      updatePayload.new_image_url = imagePath;
    }

    if (!_.isEqual(initialValues.email, formik.values.email)) {
      updatePayload.new_email_address = formik.values.email;
    }

    if (!_.isEqual(initialValues.user_type, formik.values.user_type)) {
      updatePayload.new_user_type = formik.values.user_type;
    }

    // Check if there are actual changes to submit
    if (Object.keys(updatePayload).length > 1) {
      // Save the project data
      return performAction(updatePayload, 'POST'); // Use POST for updating projects
    }
  }

  const handleImageSelected = (file) => {
    setImageSelected(true);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: userValidationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  if (editUserLoading) {
    return <LoadingIcon showText />
  }

  if (editUserError) {
    return <div><ErrorCard cardType={CARD_TYPES.EMPTY} /> {editUserError.message}</div>; // Handle error state
  }

  return (
    <>
      <h2>
        {addingUser ? 'Add New User' : 'Edit User'}
      </h2>

      <form onSubmit={formik.handleSubmit} className="flex flex-col justify-center w-full max-w-4xl">

        <Typography variant="h5" className="text-blue-500 mt-4">Display Name</Typography>
        <TextInput
          label="Display Name"
          name="displayName"
          placeholder="Enter the display name for the user"
          value={formik.values.displayName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.displayName && formik.errors.displayName}
          errorMessage={formik.errors.displayName}
          maxLength={MAX_LENGTH_DISPLAY_NAME}
        />

        <Typography variant="h5" className="text-blue-500 mt-4">Surname</Typography>
        <TextInput
          label="Surname"
          name="surname"
          placeholder="Enter the surname for the user"
          value={formik.values.surname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.surname && formik.errors.surname}
          errorMessage={formik.errors.surname}
          maxLength={MAX_LENGTH_SURNAME}
        />

        <Typography variant="h5" className="text-blue-500 mt-4">Given Name</Typography>
        <TextInput
          label="Given Name"
          name="givenName"
          placeholder="Enter the given name for the user"
          value={formik.values.givenName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.givenName && formik.errors.givenName}
          errorMessage={formik.errors.givenName}
          maxLength={MAX_LENGTH_GIVEN_NAME}
        />

        <Typography variant="h5" className="text-blue-500 mt-4">Email Address</Typography>
        <TextInput
          label="Email Address"
          name="email"
          placeholder="Enter the email address for the user"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
          errorMessage={formik.errors.email}
          maxLength={MAX_LENGTH_EMAIL}
        />

        <Typography variant="h5" className="text-blue-500 mt-4">User Type</Typography>
        <Select
          className='mt-Select-button'
          label="User Type"
          name="user_type"
          value={formik.values.user_type}
          onChange={(value) => formik.setFieldValue('user_type', value)}
          onBlur={formik.handleBlur}
          error={formik.touched.user_type && formik.errors.user_type}
        >
          <SelectItem className='mt-Select-option' value="user">User</SelectItem>
          <SelectItem className='mt-Select-option' value="admin">Admin</SelectItem>
          {adminUserDetails?.user_type === 'super_admin' && <SelectItem className='mt-Select-option' value="super_admin">Super Admin</SelectItem>}
        </Select>

        {userId !== -1 && !addingUser && editUserData && (        // Only show when editing an existing user
          <>
            <Typography variant="h5" className="text-blue-500 mt-4">User Image:</Typography>
            <ImageUploader
              ref={imageUploaderRef}
              alt={formik.values.displayName + " profile image"}
              imgSrc={formik.values.image_url || ''}
              onSavePath={(path) => doSave(path)}
              imageType="user"
              imageId={userId}
              onImageSelect={handleImageSelected}
              displayType='avatar'
            />
          </>
        )}

        <Typography variant="h5" className="text-blue-500 mt-4">Last login</Typography>
        <Typography variant="paragraph" className="text-blue-500 mt-2">{editUserData?.last_login || 'N/A'}</Typography>

        <AlertManager
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />

        <UserPasswordDialog
          isOpen={isPasswordDialogOpen}
          onClose={handleCloseDialog}
          thePassword={temporaryPassword}
        />

        <Navbar className="m-0 p-0 mt-5 flex items-center justify-end w-full p-4 gap-4 bg-gray-100">
          {userId !== -1 && !addingUser && (    // Only show when editing an existing project
            <div className="flex justify-start">
              <UserProjectsDialog
                userId={userId}
                updateUserTable={onUpdatedUser}
              />
            </div>
          )}

          <div className="flex ml-auto gap-4">
            <Button
              color="blue"
              variant="outlined"
              onClick={onCancel}
              className="flex text-blue-500 items-center gap-3"
            >
              <FaBan /> Cancel
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={!formik.dirty && !formik.isSubmitting && !imageSelected}
              className="flex items-center gap-3">
              <FaSave /> Save
            </Button>
          </div>
        </Navbar>
      </form>

    </>
  );
}

UserEditForm.propTypes = {
  userId: PropTypes.number,
  addingUser: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpdatedUser: PropTypes.func,
  organisationId: PropTypes.number,
};

export default UserEditForm;