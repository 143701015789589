import React from 'react';
import { useAuth } from '../components/navigation/Authentication.jsx';
import { Button, Alert, Typography } from "@material-tailwind/react";
import UnauthenticatedLayout from '../layouts/UnauthenticatedLayout.jsx';
import SpinningTitle from '../components/general/SpinningTitle.jsx';
import { APP_ENVIRONMENT } from '../config.js';
import { isDarkMode } from '../datahooks/metadata/DataUtils.js';

export default function Login() {
    const { signIn, loginError, isAuthenticating } = useAuth(); // loginError is now part of useAuth
   
    const doSignIn = (e) => {
        e.preventDefault();
        signIn(); // signIn is responsible for updating loginError in case of an error
    };

    return (
        <UnauthenticatedLayout>
            <div className="min-h-screen flex items-start justify-center bg-transparent py-6 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div className='flex flex-col justify-center items-center'>
                        {isDarkMode() ? (
                            <img src="/assets/logo/tiqtoq_rev.svg" className="mx-auto h-24 m-12 w-auto" alt="tiQtoQ Logo" data-testid='tiqtoq-login-logo' />
                        ) : (
                            <img src="/assets/logo/tiqtoq.svg" className="mx-auto h-24 m-12 w-auto" alt="tiQtoQ Logo" data-testid='tiqtoq-login-logo' />
                        )}
                        <SpinningTitle theTitle="Dashboard" />  
                    </div>
                    <Button 
                        color="blue" 
                        size="lg" 
                        onClick={doSignIn} 
                        className="w-full"
                        disabled={isAuthenticating}
                    >
                        {isAuthenticating ? "Logging in..." : "Login"}
                    </Button>
                    {/* Display login error if it exists */}
                    {loginError && <Alert id="errorAlert" color="pink">{loginError}</Alert>}
                    <Typography variant='paragraph'>{`Environment: ${APP_ENVIRONMENT}`}</Typography>
                </div>
            </div>
        </UnauthenticatedLayout>
    );
}