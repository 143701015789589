import React from 'react';

import AdminLayoutOrganisation from '../../layouts/AdminLayoutOrganisation';
import Projects from '../../components/admin/project/Projects';

export default function AdminProjects() {

  return (
    <AdminLayoutOrganisation>
      <Projects />
    </AdminLayoutOrganisation>
  );
}