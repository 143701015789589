import React from 'react';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout.jsx';
import { Card  } from "@tremor/react";
import { Typography } from '@material-tailwind/react';

export default function Faq() {
    
    return (
        <AuthenticatedLayout>
        <div className="mt-1 p-0 md:p-6 bg-gray-50 rounded-lg border ">
            <Card className="flex w-full h-full flex-col static">
                <Typography variant='h2'>FAQ</Typography>
                <div className="flex md:flex-row flex-col items-start">
                <div className="md:order-2 order-1 w-full lg:w-1/6 md:w-1/5 flex-shrink-0 text-center mb-3">
                    <img src="/assets/logo/tiqtoq.png" className="h-auto w-1/4 md:w-full max-w-full block mx-auto" alt="tiQtoQ Logo" />
                </div>
                <Typography variant='paragraph' className="md:order-1 order-2 flex-grow">
                    Welcome to the disQo Dashboard, the essential tool for overseeing the quality of your digital products and releases. The platform offers a comprehensive suite of features for in-depth User Interface analysis, robust API monitoring, detailed performance metrics, thorough security audits, and accessibility compliance. Designed for efficiency and ease of use, the disQo Dashboard is your reliable solution in enhancing and securing your digital offerings with precision and expertise.
                </Typography>
            </div>
            </Card>
        </div>
        
        </AuthenticatedLayout>
    );
}