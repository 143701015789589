import React, { useState } from "react";
import { Navbar, Button } from '@material-tailwind/react';
import { IoAddCircle } from "react-icons/io5";

import { useOrgContext } from '../../../datahooks/admin/organisation/AdminOrgIDContext.jsx';
import useUserList from "../../../datahooks/admin/user/useUserList.js";
import useUserManager from '../../../datahooks/admin/user/useUserManager.js';

import LoadingIcon from "../../navigation/LoadingIcon.jsx";
import UserEditForm from "./UserEditForm.jsx";
import AlertManager from "../../general/AlertManager.jsx";
import UsersTable from "./UsersTable.jsx";

export default function Users() {
    const { organisationData } = useOrgContext();
    const { usersData, loadingUsers, usersError, refetchUsers } = useUserList(organisationData?.id);

    const [ editingUser, setEditingUser ] = useState(-1);
    const [ addingUser, setAddingUser ] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const userUpdated = (aResponse) => {
        refetchUsers();
        setSuccessMessage('User updated successfully.');
    }

    const userSaveFailed = (error) => {
        setErrorMessage(`Failed to save user: ${error?.message || 'Unknown error'}`);
    }
    
    const { performAction } = useUserManager(userUpdated, userSaveFailed);

    const handleAddUser = () => {
        setAddingUser(true);
    }

    const handleDeleteUser = async (userId, newStatus) => {
        const payload = {}; // Initialise payload

        if (newStatus === 'inactive') {
            payload.del_user_id = userId; // Set the user_id
            await performAction(payload, 'DELETE');
        } else {
            payload.edit_user_id = userId; // Set the user_id
            payload.new_user_status = 'active';
            await performAction(payload, 'POST');
        }
    }

    if (loadingUsers) {
        return <LoadingIcon showText />
    }

    if (usersError) {
       return <div>Error: {usersError}</div>
    }

  return (
    <div className="flex flex-col w-full h-full text-blue-500 items-center p-4">
        <div className="w-full max-w-screen-2xl flex items-center justify-center flex-col">
            {addingUser && <UserEditForm 
                                addingUser={addingUser} 
                                onCancel={() => { setAddingUser(false)} } 
                                onUpdatedUser={() => {
                                    setAddingUser(false);
                                    refetchUsers();
                                    }
                                }
                                organisationId={organisationData?.id}
                            />}

            {editingUser !== -1 && <UserEditForm 
                                        userId={editingUser} 
                                        onCancel={() => { setEditingUser(-1)}}
                                        onUpdatedUser={() => refetchUsers()}
                                    />}

            {!addingUser && editingUser === -1 && (
                <UsersTable 
                    usersData={usersData} 
                    onEditUser={(userId) => setEditingUser(userId)}
                    onDeleteUser={(userId, newStatus) => handleDeleteUser(userId, newStatus)}
                />
            )}

            <AlertManager 
                className="m-2"
                successMessage={successMessage} 
                setSuccessMessage={setSuccessMessage} 
                errorMessage={errorMessage} 
                setErrorMessage={setErrorMessage} 
            />

            {(!addingUser && editingUser === -1) && (
            <Navbar className='m-0 p-0 mt-5 flex items-center justify-between w-full p-4 gap-3 bg-gray-100'>
                <div className='flex gap-3'>
                    <Button 
                        size="sm" 
                        variant="outlined" 
                        className="flex text-blue-500 items-center gap-3"
                        onClick={() => handleAddUser()}
                        disabled={addingUser || editingUser !== -1}
                    >
                        <IoAddCircle size={20} /> Add New
                    </Button>
                </div>
            </Navbar>
            )}
        </div>
    </div>
  );
}