import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../config';
import { useAuthenticatedQuery } from '../useAuthenticatedQuery';
import { BlobServiceClient } from '@azure/storage-blob';
import logError from '../metadata/ErrorLogging';

const useUploadImage = (onSavePath) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const mutation = useMutation(async ({ file, sasToken, type, id }) => {
        const fileExtension = file.type.split('/').pop();
        const filename = `${type}_${id}.${fileExtension}`;

        const blobServiceClient = new BlobServiceClient(sasToken);
        const containerClient = blobServiceClient.getContainerClient('');
        const blobClient = containerClient.getBlockBlobClient(filename);

        // Use uploadData instead of uploadBrowserData
        await blobClient.uploadData(file, {
            onProgress: (progress) => {
                setUploadProgress((progress.loadedBytes / file.size) * 100); // Update progress
            },
            blobHTTPHeaders: { blobContentType: file.type } // Set the content type based on the file type
        });
        
        return filename; // Or return the full path if needed
    }, {
        onError: (error) => logError('Error uploading image', error, {}),
        onSuccess: (filename) => {
            // Get the current date and time
            const currentDateTime = new Date().toISOString().replace(/[-:]/g, '').replace(/\.\d+/, ''); // Format YYYYMMDDHHMMSS
        
            // Append the current date and time to the filename to prevent caching
            const filenameWithTimestamp = `${filename}?${currentDateTime}`;
        
            // Call the onSavePath function with the updated filename
            onSavePath(filenameWithTimestamp); 
        }
    });

    return { ...mutation, uploadProgress };
};

const useSasToken = () => {
    const [triggerFetch, setTriggerFetch] = useState(false);

    const fetchSasToken = async (token) => {
        const url = `${API_BASE_URL}disqoadmin/image_token`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-functions-key': FUNCTION_KEY
            }
        });
        if (!response.ok) {
            logError('Failed to fetch SAS token', response, {});
            throw new Error(`Failed to fetch SAS token, status ${response.status}`);
        }
        
        const data = await response.json();
        return data.sasToken;
    };

    const projectScope = [PROJECT_SCOPE];
    const { data: sasToken, isLoading, error } = useAuthenticatedQuery(
        ['sasToken'], 
        fetchSasToken, 
        projectScope, 
        {
            enabled: triggerFetch,  // Triggered manually
            onSuccess: () => setTriggerFetch(false),  // Reset trigger after successful fetch
            staleTime: 2 * 60 * 1000, // The data will be considered stale after 2 minutes
        }
    );

    const triggerFetchToken = () => {
        setTriggerFetch(true);
    };

    return { sasToken, isLoading, error, triggerFetchToken };
};

export { useUploadImage, useSasToken };

useUploadImage.propTypes = {
    onSavePath: PropTypes.func
};