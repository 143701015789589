import React from 'react';
import { HiHome, HiUsers } from 'react-icons/hi';
import { VscOrganization } from "react-icons/vsc";
import { BsClockHistory } from "react-icons/bs";
import { LuTestTubes } from "react-icons/lu";
import { PiFoldersFill } from "react-icons/pi";
import PropTypes from 'prop-types';
import { List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import useUser from '../../datahooks/useUser';

const MenuItem = ({ to, icon: Icon, label, isCollapsed, inDrawer, testId }) => (
  <Link to={to}>
    <ListItem data-testid={testId}>
      <ListItemPrefix 
        title={isCollapsed && !inDrawer ? label : ""}
        aria-label={isCollapsed && !inDrawer ? label : undefined}>
        <Icon className="h-6 w-6" />
      </ListItemPrefix>
      <Typography 
        variant='paragraph' 
        className={`${inDrawer || !isCollapsed ? 'block' : 'hidden'} transition-opacity duration-300`} 
        color='blue'>
        {label}
      </Typography>
    </ListItem>
  </Link>
);

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool,
  inDrawer: PropTypes.bool,
  testId: PropTypes.string.isRequired
};

const Divider = () => (
  <hr className="my-2 border-blue-gray-100 dark:border-dark-tremor-border" />
);

const MenuLinksAdmin = ({ inDrawer = false, isCollapsed = false }) => {
  const { user: userDetails } = useUser();

  const standardMenuItems = [
    {
      to: "/admin",
      icon: HiHome,
      label: "Admin Home",
      testId: "menulinksadmin-home"
    },
    {
      to: "/admin_organisation",
      icon: VscOrganization,
      label: "Organisation",
      testId: "menulinksadmin-organisation"
    },
    {
      to: "/admin_projects",
      icon: PiFoldersFill,
      label: "Projects",
      testId: "menulinksadmin-projects"
    },
    {
      to: "/admin_users",
      icon: HiUsers,
      label: "Users",
      testId: "menulinksadmin-users"
    }
  ];

  const superAdminMenuItems = [
    {
      to: "/admin_testtypes",
      icon: LuTestTubes,
      label: "Test Types",
      testId: "menulinksadmin-testtype"
    },
    {
      to: "/admin_auditlog",
      icon: BsClockHistory,
      label: "Audit Log",
      testId: "menulinksadmin-auditlogs"
    }
  ];

  return (
    <List data-testid="menulinksadmin" className='min-w-full p-1'>
      {standardMenuItems.map((item, index) => (
        <React.Fragment key={item.testId}>
          {index === 1 && <Divider />}
          <MenuItem 
            {...item}
            inDrawer={inDrawer}
            isCollapsed={isCollapsed}
          />
        </React.Fragment>
      ))}
      
      {userDetails?.user_type === 'super_admin' && (
        <>
          <Divider />
          {superAdminMenuItems.map(item => (
            <MenuItem
              key={item.testId}
              {...item}
              inDrawer={inDrawer}
              isCollapsed={isCollapsed}
            />
          ))}
        </>
      )}
    </List>
  );
};

MenuLinksAdmin.propTypes = {
  inDrawer: PropTypes.bool,
  isCollapsed: PropTypes.bool,
};

export default MenuLinksAdmin;