import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';

import axios from 'axios';
import { API_ADMIN_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import useUser from '../../useUser.js';

export default function useOrganisations() {
  const { user:userDetails } = useUser();

  const fetchOrganisations = async (token) => {
    try {
      const response = await axios.get(`${API_ADMIN_URL}organisations`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });
      return response.data;
    } catch (error) {
      logError('Failed to load organisations in useOrganisations', error, {}); // Error during fetch
      throw error;
    }
  };
  
  const { data: organisationsData = [], isLoading, refetch, error } = useAuthenticatedQuery(`organisations_${userDetails?.id}`, fetchOrganisations, [PROJECT_SCOPE], {
    staleTime: Infinity, // The data will never be considered stale. If the result is in a pending state, a refetch button will be available
  });

  return { organisationsData, organisationsLoading: isLoading, refetchOrganisations: refetch, organisationsError: error };
}
